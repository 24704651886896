import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import AnalyticsLeftColumn from "../../components/AnalyticsLeftColumn";
import AnalyticsRightColumn from "../../components/AnalyticsRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/analytics"
);

  const Analytics = ({ location }) => { // Recevoir location en tant que prop
    return (<Layout location={location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        {/* 2. définir les métadonnées SEO */}
        <SEO
          title="Tutoriels sur l'analyse web, GA & Google Tag Manager"
          lang="fr"
          description="Apprenez les bases et les concepts avancés de l'analyse web, Google Analytics & GTM avec des tutoriels pas à pas. Améliorez vos performances marketing avec des insights actionnables!"
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/fr/analytique"
        />
        <MainFrontpage>
          <AnalyticsLeftColumn />
          <AnalyticsRightColumn />
        </MainFrontpage>
      </React.Fragment>
  
    </Layout>
    );
  };
  
  export default React.memo(Analytics);
  